// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";




.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.dropdown-menu {
  display: none;
}

th a.sortable {
  display: block;
  position: relative;
  padding-right: 30px;
  span {
    position: absolute;
    top: -2px;
    right: 0;
    svg {
      display: none;
    }
  }

  &:hover {
    span {
      svg.down {
        display: block;
      }
    }
  }
  &.asc {
    span {
      svg.up {
        display: block;
      }
    }
    &:hover {
      span {
        svg.up {
          display: none;
        }
        svg.down {
          display: block;
        }
      }
    }
  }
  &.desc {
    span {
      svg.down {
        display: block;
      }
    }
    &:hover {
      span {
        svg.down {
          display: none;
        }
        svg.up {
          display: block;
        }
      }
    }
  }

}

.note-content {
 border-bottom: 1px solid #eee;
 padding-bottom: 8px;
 margin-bottom: 8px;

}

.note-content:last-child {
  border-bottom: 0 none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.trial-button {
  position: relative;
  padding-right: 30px !important;
  background: rgba(57, 86, 126, 0.05);

}
.trial-button:after {
  position: absolute;
  top: 12px;
  right: 16px;
}

.dropdown-mega-menu-option  {
  white-space: nowrap;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  margin: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.dropdown-mega-menu-child  {
  white-space: nowrap;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.nomarg {
  margin: 0 !important;
}

.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-btn {
  width: 120px;
}

.modal-body-editor {
  height: 500px;
}

.inp-group {
  width: 75px;
  display: block;
}

.ql-container {
  height: 320px !important;
}
@media (max-width: 1099.98px) {
  .app-header-menu {
      display: block;
      margin-left: 1rem;
  }
  .dropdown-mega-menu-option, 
  .dropdown-mega-menu-child {
    max-width: 300px;
  }

  .trial-button {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    strong, span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.file-button-note {
  border: medium none;
  background-image: none; 
  background: transparent;
  background-color: transparent;
}

.file-button-note-icon {
  border: 2;
  border-radius: 50%;
  background-image: none; 
  background: transparent;
  background-color: transparent;
}

.btn-xs {
  padding: 0.01rem 0.5rem;
  font-size: 0.8rem !important;
  line-height: 1.2;
}

.btn-outline-grey {
  border: 1px solid #aaa;
  color: #aaa;
  background: transparent;
}

.form-group-datepicker-range {
  position: relative;
}

.dashboard-form-control {
  //position: relative;
  width: 232px;
  height: 36px;
  padding-left: 10px;
}

.popper-datepicker-dashboard .react-datepicker__day--in-range{
  background-color: #abb6ca !important;
}

.btn-clear-calendar {
  position: absolute;
  left: 252px;
  top: -1px;
  z-index: 101;
  padding: 0.7em;
}

.calendar-datepicker-dashboard .react-datepicker__month {
  margin: 0;
}

.bt-send-announcement {
  border: 1px solid #d1d2db;
  border-radius: 0.29rem;
  color: #999;
  font-weight: normal;
}

.react-datepicker__close-icon  {
  position: absolute;
  top: 12px;
}

.form-data-page-progress-2::-moz-progress-bar { background-color: #55b2cb; }
.form-data-page-progress-2::-webkit-progress-value { background-color: #55b2cb; }
.form-data-page-progress-2 { background-color: #55b2cb; }

.square-legend {
  height: 1rem;
  width: 1rem;
  background-color: #55b2cb;
  border-radius: 0.2rem;
  margin-top: 4px;
}

.square-legend-passed {
  height: 1rem;
  width: 1rem;
  background-color: #39567e;
  border-radius: 0.2rem;
  margin-top: 4px;
}

.ellipsis-col {
  max-width: 200px;
  position: relative;
}

.ellipsis-col span {
  max-width: 400px;
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-col::after {
  content: attr(data-full-note);
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  color: #3b3e66;
  background-color: #FFF;
  padding: 0.75rem;
  white-space: pre-wrap;
  z-index: 1;
  min-height: 48px;
  transition: all 0.2s ease-in-out;
}

.ellipsis-col.hovered::after {
  content: attr(data-full-note);
  background-color: #fffbf2;
  display: block;
  transition: all 0.2s ease-in-out;
}