// Core

.dropdown-mega-menu-lg {
  min-width: 200px;
  max-height: 400px;  // Set max height to prevent overflow
  overflow-y: auto;   // Enable vertical scrolling
  overflow-x: hidden; // Prevent horizontal overflow
  border: 1px solid #ddd; // Optional: Adds a subtle border
}

/* Optional: Style scrollbar for better appearance */
.dropdown-mega-menu-lg::-webkit-scrollbar {
  width: 8px;
}

.dropdown-mega-menu-lg::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.dropdown-mega-menu-lg::-webkit-scrollbar-track {
  background: transparent;
}

.dropdown-mega-menu-md {
  width: 25rem;
}

.app-header-menu {
  position: relative;
  transition: $transition-base;
  transform: scale(1);
  visibility: visible;
  transition-delay: .2s;

  @include media-breakpoint-down(md) {
    display: none;
  }
}
